import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

import Layout from '../layout/Layout';
import ChatInput from '../components/ChatInput';
import ChatOverlay from '../components/ChatOverlay';
import ChatMessage from '../components/ChatMessage';
import EngineSelector from '../components/EngineSelector';
import NotFound from './notfound';
import Loading from './loading';

export default function Chat({ user }) {
    const { id } = useParams();
    const [chat, setChat] = useState(id);
    const [isOverlayHidden, setIsOverlayHidden] = useState(false);
    const [messages, setMessages] = useState([]);
    const [suggestionMessage, setSuggestionMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [newMessage, setNewMessage] = useState(null);
    const [answering, setAnswering] = useState(false);
    const [engine, setEngine] = useState([]);

    // Trigger to get the messages of a conversation
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (id !== undefined) {
                    const response = await axios.get(process.env.REACT_APP_API_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/chatbot/GetMessages', {
                        headers: {
                            'Authorization': 'Bearer ' + user['accessToken'],
                            'accept': 'application/json'
                        },
                        params: {
                            chat_id: id
                        },
                        withCredentials: false,
                    });
                    setChat(id);
                    setMessages(response.data.messages);
                    setLoading(false);
                }
                else {
                    setMessages([]);
                    setLoading(false);
                }
            } catch (error) {
                setMessages([]);
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [user, id]);

    // Trigger if overlay must be shown
    useEffect(() => {
        if (messages.length === 0) {
            setIsOverlayHidden(false);
        }
        else {
            setIsOverlayHidden(true);
        }
    }, [messages]);

    const handleSuggestionChatText = (suggestion) => {
        setSuggestionMessage(suggestion);
    };

    const handleUserMessage = async (message) => {
        setAnswering(true);
        // Set user message and blinking cursor for chatbot
        setMessages([...messages, { role: "user", content: message }, { role: "assistant", content: "●", additional_context: {}, conversation: {} }]);

        // Request to get an ansewer
        try {
            const params = {
                chat_id: chat,
                stream: true,
                skip_user_info: false,
                skip_conversation_title: false,
                function: engine.function,
                knowledge_type: engine.knowledge
            };
            const paramsCleaned = queryString.stringify(params);

            const response = await fetch(process.env.REACT_APP_API_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/chatbot/GetAnswer?' + paramsCleaned, {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + user['accessToken'],
                    "Content-Type": "application/json",
                    'Accept': 'text/event-stream'
                },
                body: JSON.stringify({ "message": message })
            });
            if (!response.ok) {
                toast.error("Something went wrong while getting an answer, please try again.", {})
            }

            // Here we start prepping for the streaming response
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            const loopRunner = true;

            let answer = '';
            let additional_context = {};
            let conversation = {};
            while (loopRunner) {
                // Here we start reading the stream, until its done.
                const { value, done } = await reader.read();
                if (done) {
                    break;
                }
                const decodedChunk = decoder.decode(value, { stream: true });
                const replacedChunk = decodedChunk.replace(/}{/g, '}|{')
                const chunks = replacedChunk.split('|')

                let buffer = "";
                for (const chunk of chunks) {
                    // Append the chunk to the buffer
                    buffer += chunk;

                    // Check if the buffer contains a complete JSON object
                    let parsedChunk;
                    try {
                        parsedChunk = JSON.parse(buffer);
                        buffer = ""; // Clear the buffer if parsing is successful
                    } catch (e) {
                        // If parsing fails, continue accumulating chunks
                        continue;
                    }

                    if (parsedChunk) {
                        // Update additional_context if it exists in the parsed chunk
                        const chunkContext = parsedChunk?.additional_context;
                        if (chunkContext !== undefined) {
                            additional_context = { ...additional_context, ...chunkContext };
                        }

                        // Update conversation if it exists in the parsed chunk
                        const chunkConversation = parsedChunk?.conversation;
                        if (chunkConversation !== undefined) {
                            conversation = { ...conversation, ...chunkConversation };
                        }

                        // Define answer
                        const chunkMessage = parsedChunk?.message;
                        if (chunkMessage !== undefined) {
                            const words = chunkMessage.split(" ");
                            for (let i = 0; i < words.length; i++) {
                                if (i + 1 < words.length) {
                                    answer += words[i] + " ";
                                }
                                else {
                                    answer += words[i];
                                }
                                setMessages([...messages, { role: "user", content: message }, { role: "assistant", content: answer.trim() + "▌", additional_context: additional_context, conversation: conversation }]);
                                await new Promise(resolve => setTimeout(resolve, 1));
                            }
                        }
                    }
                }
            }
            // At the end update the complete answer without blink
            setMessages([...messages, { role: "user", content: message }, { role: "assistant", content: answer, additional_context: additional_context, conversation: conversation }]);
            setAnswering(false);

            //Update url when new chat
            if (id === undefined) {
                const chatId = response.headers.get('chat_id');
                console.log(response);
                console.log(chatId);
                setChat(chatId);
                window.history.pushState("object or string", "Title", "/chat/" + chatId);
                if (newMessage === null) {
                    setNewMessage(1);
                }
                else {
                    setNewMessage(newMessage + 1);
                };
            }
        }
        catch (error) {
            console.error('Error getting answer:', error);
            setAnswering(false);
            toast.error("An error occured when giving an answer, please try again.", {})
        }
    };

    return (
        loading ? <Loading /> : messages.length === 0 && id !== undefined ? <NotFound /> :
            <Layout user={user} newMessage={newMessage}>
                <EngineSelector onHandleEngine={setEngine} />
                <div className='flex h-full flex-col overflow-y-auto sm:pt-14'>
                    <div className="flex-1 w-full ms-0 overflow-auto">
                        {!isOverlayHidden &&
                            <div className="flex items-center justify-center h-full">
                                <ChatOverlay setSuggestionChatText={handleSuggestionChatText} engineName={engine.name} />
                            </div>
                        }
                        <div className="sm:hidden p-2"></div>
                        {messages.map((msg, index) => {
                            var last;
                            if (index + 1 === messages.length) {
                                last = true;
                            }
                            else {
                                last = false;
                            }
                            if ((msg.role === 'assistant' || msg.role === 'user') && msg.content !== null && msg?.additional_context?.show !== false) {
                                return <ChatMessage key={index} chat={chat} id={msg.additional_context?.message_id} role={msg.role} content={msg.content} last={last} user={user} liked={msg.additional_context?.like} component={msg.additional_context?.component} />;
                            } else {
                                return null;
                            }
                        })}
                    </div>
                    <div className='sticky bottom-0 bg-gray-100'>
                        <ChatInput onUserMessage={handleUserMessage} currentMessage={suggestionMessage} loading={answering} />
                    </div>
                </div>
            </Layout>
    );
}


