import ReactMarkdown from "react-markdown";
import { ClipboardIcon, HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/react/24/outline';
import { HandThumbDownIcon as HandThumbDownIconSolid, HandThumbUpIcon as HandThumbUpIconSolid } from '@heroicons/react/20/solid';
import { toast } from 'react-toastify';
import axios from 'axios';
import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import {
    atomDark
} from 'react-syntax-highlighter/dist/cjs/styles/prism'

import logo from '../assets/logo_small.png'

export default function ChatMessage({ chat, id, role, content, last, user, liked, component }) {
    const [feedback, setFeedback] = useState(liked);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    async function handleFeedback(like) {
        try {
            await axios.post(process.env.REACT_APP_API_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/chatbot/GiveFeedback', {}, {
                headers: {
                    'Authorization': 'Bearer ' + user['accessToken'],
                    'accept': 'application/json'
                },
                params: {
                    chat_id: chat,
                    message_id: id,
                    like: like
                },
                withCredentials: false,
            });

            toast.success("Successfully saved your feedback!", {});

            setFeedback(like);
            console.log("Given feedback: " + feedback);
        } catch (error) {
            console.error('Error giving feedback:', error);
            toast.error("An error occured when giving feedback, please try again.", {})
        }
    }

    function handleCopy(message) {
        navigator.clipboard.writeText(message);

        toast.info("Copied to clipboard!", {})
    }

    return (
        <div
            className={"p-2 flex gap-4 overflow-x-auto" + classNames(
                role === "assistant" ? "border-y border-solid border-gray-100 " : ""
            )}
        >
            <div className="flex gap-3 sm:max-w-3xl sm:px-6 lg:px-8 px-4 w-full mx-auto">
                <div className="flex-shrink-0 flex flex-col relative items-end">
                    <div className="pt-0.5">
                        <div className="bg-red-200 gizmo-shadow-stroke flex h-6 w-6 items-center justify-center overflow-hidden rounded-full">
                            <div className="relative flex">
                                {role === "assistant" ?
                                    <img alt="User" loading="lazy" width="24" height="24" decoding="async" data-nimg="1" className="rounded-sm" src={logo} /> :
                                    user.email.charAt(0).toUpperCase()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative w-full pr-8 sm:pr-12 flex-col">
                    <div className="font-bold text-gray-900 text-md">
                        {role === "assistant" ? "New Black" : "You"}
                    </div>
                    <div className="flex-col w-full mt-2 gap-1 md:gap-3 text-gray-800 text-md">
                        {content === '●'
                            ? <div className="relative">
                                <div className="absolute mt-2 w-3 h-3 rounded-full bg-gray-600 animate-ping overflow-x-visible"></div>
                                <div className="absolute mt-2 w-3 h-3 rounded-full bg-gray-600"></div>
                            </div>
                            : null}
                        <ReactMarkdown
                            className={`space-y-5 break-words ${content === '●' ? 'text-gray-100' : 'text-gray-700'}`}
                            children={content}
                            components={{ 
                                ul: ({ node, ...props }) => ( <ul className="block list-disc list-outside" {...props} /> ), 
                                ol: ({ node, ...props }) => ( <ul className="block list-decimal list-outside" {...props} /> ), 
                                li: ({ node, ...props }) => ( <li className="marker:text-gray-400 marker:font-heading marker:text-md pl-1" {...props} /> ), 
                                code({ node, inline, className, children, ...props }) {
                                    const match = /language-(\w+)/.exec(className || '')
                                    return !inline && match ? (
                                        <div className="bg-gray-800 rounded-md my-5 overflow-x-auto">
                                            <div className="flex text-gray-200 rounded-t-md font-heading text-xs pt-1.5 pb-0.5 px-3 justify-between">
                                                <div className="text-start mr-auto">
                                                    {match[1]}
                                                </div>
                                                <button onClick={() => handleCopy(String(children).replace(/\n$/, ''))} className="flex">
                                                    <ClipboardIcon className="w-3.5 h-full text-end" />
                                                    <div className="ml-1 text-end">
                                                        Copy code
                                                    </div>
                                                </button>
                                            </div>
                                            <SyntaxHighlighter
                                                children={String(children).replace(/\n$/, '')}
                                                language={match[1]}
                                                style={atomDark}
                                                PreTag="div"
                                                className="mockup-code bg-black text-xs rounded-b-md scrollbar-thin scrollbar-track-base-content/5 scrollbar-thumb-base-content/40 scrollbar-track-rounded-md scrollbar-thumb-rounded"
                                                showLineNumbers={true}
                                                useInlineStyles={true}
                                                
                                                {...props}
                                            />
                                        </div>
                                    ) : (
                                        <code className="text-xs  inline-flex text-left items-center space-x-4 bg-neutral-800 text-blue-300 rounded-md px-1.5 py-0.5" {...props}>
                                            {children}
                                        </code>
                                    )
                                },
                            }}
                        />
                        <div className="flex">
                            {component?.type === 'button' ? <a className="my-4 text-white bg-black hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-400 font-medium rounded-lg text-sm font-heading px-4 py-3 text-center" href={component?.href}>{component?.text}</a> : null}
                        </div>
                        {role === "assistant" ?
                            <div className="group mt-2 flex justify-start gap-3 empty:hidden">
                                <div className="text-gray-400 flex self-end lg:self-center justify-center lg:justify-start mt-0 -ml-1">
                                    <span>
                                        <button title="Copy" onClick={() => handleCopy(content)} className="flex items-center gap-1.5 rounded-md p-1 text-xs hover:text-gray-950 dark:text-gray-400 dark:hover:text-gray-200 disabled:dark:hover:text-gray-400 md:group-hover:visible md:group-[.final-completion]:visible">
                                            <ClipboardIcon className={` ${last ? null : 'invisible'} group-hover:visible w-4 stroke-2`} />
                                        </button>
                                    </span>
                                    <span>
                                        <button title="Like" onClick={() => handleFeedback(true)} className="flex items-center gap-1.5 rounded-md p-1 text-xs hover:text-gray-950 dark:text-gray-400 dark:hover:text-gray-200 disabled:dark:hover:text-gray-400 md:group-hover:visible md:group-[.final-completion]:visible">
                                            {feedback === true
                                                ? <HandThumbUpIconSolid className={` ${last ? null : 'invisible'} group-hover:visible w-4 stroke-2`} />
                                                : <HandThumbUpIcon className={` ${last ? null : 'invisible'} group-hover:visible w-4 stroke-2`} />}
                                        </button>
                                    </span>
                                    <span>
                                        <button title="Dislike" onClick={() => handleFeedback(false)} className="flex items-center gap-1.5 rounded-md p-1 text-xs hover:text-gray-950 dark:text-gray-400 dark:hover:text-gray-200 disabled:dark:hover:text-gray-400 md:group-hover:visible md:group-[.final-completion]:visible">
                                            {feedback === false
                                                ? <HandThumbDownIconSolid className={` ${last ? null : 'invisible'} group-hover:visible w-4 stroke-2`} />
                                                : <HandThumbDownIcon className={` ${last ? null : 'invisible'} group-hover:visible w-4 stroke-2`} />}
                                        </button>
                                    </span>
                                </div>
                            </div>
                            : <div className="mt-2 flex h-5" />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
