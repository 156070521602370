import React from 'react';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { signInWithEmailLink, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailAndPassword } from "firebase/auth";
import { SparklesIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

import { auth } from "./Firebase";

export default function LoginForm() {
    const [confirm, setConfirm] = useState(false);
    const [emailForConfirm, setEmailForConfirm] = useState('');
    const [signinType, setSigninType] = useState('password');

    useEffect(() => {
        const handleSignInWithEmailLink = async () => {
            if (isSignInWithEmailLink(auth, window.location.href)) {
                let email = window.localStorage.getItem('emailForSignIn');
                if (!email) {
                    setConfirm(true);
                    toast.info("Please provide your email for confirmation.", {})
                }
                else {
                    try {
                        await signInWithEmailLink(auth, email, window.location.href);
                        window.localStorage.removeItem('emailForSignIn');
                        toast.success("Successfully logged in!", {})
                    } catch (error) {
                        var message = "Something went wrong.";
                        console.log(error.code);
                        switch (error.code) {
                            case "auth/invalid-action-code":
                                message = "Sign-in link is expired or already used.";
                                break;
                            case "auth/invalid-email":
                                message = "Email provided is invalid.";
                                break;
                            default:
                                message = "Something went wrong.";
                        }
                        window.localStorage.removeItem('emailForSignIn');
                        toast.error(message, {})
                    }
                }
            }
        };
        handleSignInWithEmailLink();
    }, [emailForConfirm]);


    async function handleLogin(e) {
        e.preventDefault();
        var email = e.target.username.value;
        var password = e.target.password?.value;

        var rootUrl = window.location.href;
        const actionCodeSettings = {
            url: rootUrl,
            handleCodeInApp: true
        }

        if (signinType === 'email') {
            sendSignInLinkToEmail(auth, email, actionCodeSettings)
                .then(() => {
                    toast.info("An email was sent to your email address. Click the link in the email to login.", {})
                    window.localStorage.setItem('emailForSignIn', email);
                })
                .catch((error) => {
                    toast.error("Username is not known.", {})
                })
        }
        else {
            signInWithEmailAndPassword(auth, email, password)
                .then(() => {
                    toast.success("Successfully logged in!", {})
                })
                .catch((error) => {
                    toast.error("Username and/or password is not correct.", {})
                })
        }
    }

    async function handleConfirm(e) {
        e.preventDefault();
        var email = e.target.username.value;
        window.localStorage.setItem('emailForSignIn', email);
        setEmailForConfirm(email);
    }

    async function handleSigninType() {
        if (signinType === 'password') {
            setSigninType('email');
        }
        else {
            setSigninType('password');
        }
        
    }

    return (
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 id="LoginFormTitle" className="text-xl text-center font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                    {`${confirm ? "Confirm Email" : "Sign In"}`}
                </h1>
                <form className="space-y-4 md:space-y-6" onSubmit={confirm ? handleConfirm : handleLogin}>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
                        <input type="text" name="username" id="username" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-600 focus:border-gray-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter your username" required={true}></input>
                    </div>
                    {signinType === 'password'
                    ? <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                        <input type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-600 focus:border-gray-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required={true}></input>
                    </div>
                    : null }
                    <div className="flex items-center justify-between" hidden={confirm}>
                        <a href="mailto:jaap@newblack.io" className="text-sm font-medium text-blue-500 hover:text-blue-800 dark:text-primary-500" hidden={confirm}>Forgot username?</a>
                    </div>
                    <button type="submit" className="w-full text-white bg-black hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">{`${confirm ? "Confirm" : "Sign In"}`}</button>
                </form>
                <button onClick={() => handleSigninType()} className="group flex items-center justify-between border border-gray-300 bg-gray-50 rounded-lg" hidden={confirm}>
                    <SparklesIcon className="pl-3 w-12 text-gray-400 group-hover:text-gray-600" hidden={confirm} />
                    <div className="text-sm text-start font-normal py-2.5 px-3 text-gray-400 group-hover:text-gray-600 dark:text-primary-500" hidden={confirm}>
                        <span dangerouslySetInnerHTML={{ __html: `${signinType === 'password' ? "Long password? We can send you a <b>magic link</b> for a password-free sign-in." : "We'll email you a magic link for a password-free sign-in. <b>Or you can sign in manually.</b>"}` }} />
                    </div>
                    <ChevronRightIcon className="pr-3 w-12 text-gray-400 group-hover:text-gray-600" hidden={confirm} />
                </button>
            </div>
        </div>
    );
}

