import { ChevronLeftIcon, TicketIcon, ExclamationCircleIcon, ArrowLeftEndOnRectangleIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'

export default function TicketInfo({ data, onChangeTicketDetails }) {

    const handleHideDetails = (event) => {
        onChangeTicketDetails();
    };

    return (
        <div className="border-b border-t border-gray-200 bg-white shadow-sm rounded-lg sm:border">
            <div className="flex items-center border-b border-gray-200 p-4 sm:grid-cols-5 sm:gap-x-6 sm:p-6">
                <div className='grid grid-cols-1 w-full'>
                    <div className='flex items-center w-full'>
                        <a href={"/tickets"} className="flex text-sm font-medium text-gray-500 hover:text-gray-700">
                            <ChevronLeftIcon className='w-4' />
                            <span className='ml-1'>Support Tickets</span>
                        </a>
                        <button onClick={handleHideDetails} className="max-sm:hidden flex ml-auto text-sm font-medium text-gray-500 hover:text-gray-700">
                            <span id="hideTicketDetailsTitle" className='mr-1'>Hide ticket details</span>
                            <ArrowLeftEndOnRectangleIcon className='w-4' />
                        </button>
                    </div>
                    <div className='w-full mt-6'>
                        <span className={`items-center justify-center rounded-md border bg-white py-1 px-3 text-sm font-medium ${data?.status === 4 || data?.status === 5 ? 'border-green-600 text-green-600' : 'border-purple-600 text-purple-600'}`}>
                            {data?.status_label}
                        </span>
                    </div>
                    <div className='w-full mt-2 font-heading text-lg sm:text-2xl font-extrabold'>
                        {data?.subject}
                    </div>
                    <div className='w-full flex items-center mt-1 text-sm text-gray-700'>
                        <TicketIcon className='w-4 mr-1' />
                        <span>Ticket #{data.id}</span>
                        <ExclamationCircleIcon className='w-4 ml-4 mr-1' />
                        <span>{data?.priority_label} Priority</span>
                    </div>
                    <div className="grid grid-cols-[max-content_1fr] grid-rows-2 mt-6 items-center">
                        <div className="row-span-2 mr-3 inline-flex items-center justify-center w-10 h-10 text-md bg-red-200 rounded-lg border-gray-200 border-1 text-gray-800 font-thin">
                            {data?.requester?.name.substring(0, 1)}
                        </div>
                        <div id='ticketDescription' className='w-full font-medium text-sm text-sky-600'>
                            {data?.requester?.name}
                        </div>
                        <div id='ticketDescription' className='text-gray-700 font-normal text-xs'>
                            {data?.created_at}
                        </div>
                    </div>
                    {data?.attachments.length > 0
                        ? <div>
                            <div className='w-full mt-6 font-medium text-sm'>
                                Attachments
                            </div>
                            <div className='flex mt-2'>
                                {data?.attachments.map((attachment, index) => (
                                    <a key={index} className='group bg-gray-700 inline-block w-16 h-16 rounded-md relative mr-2' href={attachment.attachment_url} target="_blank" rel="noreferrer">
                                        <img className='object-cover w-16 h-16 rounded-md mr-2 group-hover:opacity-40' src={attachment.attachment_url} alt={attachment.name} />
                                        <ArrowTopRightOnSquareIcon className='absolute inset-0 m-auto w-5 h-5 text-gray-200 invisible group-hover:visible' />
                                    </a>
                                ))}
                            </div>
                        </div>
                        : null}
                    <div className='w-full mt-6 font-medium text-sm'>
                        Description
                    </div>
                    <div id='ticketDescription' className='w-full text-sm' dangerouslySetInnerHTML={{ __html: data.description }}></div>
                </div>
            </div>
        </div>
    )
}


