import React, { useState } from 'react';
import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'

import {
    TrophyIcon,
    BoltIcon,
    AcademicCapIcon,
    CodeBracketSquareIcon,
    TicketIcon,
    BookOpenIcon,
    ChevronDownIcon
} from '@heroicons/react/24/outline';

const engines = [
    {
        name: 'EVA Hero',
        description: 'Just knows everything.',
        icon: TrophyIcon,
        function: 'auto',
        knowledge: null
    },
    {
        name: 'Scripting Legend',
        description: 'The legend in the scripting language of EVA.',
        icon: CodeBracketSquareIcon,
        function: 'generate_script',
        knowledge: 'docs'
    },
    {
        name: 'Knowledge Guy',
        description: 'Has a bookcase with all docs, APIs, and support cases.',
        icon: AcademicCapIcon,
        function: 'get_information',
        knowledge: null
    },
    {
        name: 'Docs Nerd',
        description: 'Has read every single page of the documentation.',
        icon: BookOpenIcon,
        function: 'get_information',
        knowledge: 'docs'
    },
    {
        name: 'API Guru',
        description: 'The guru on the usage of the typings.',
        icon: BoltIcon,
        function: 'get_api_information',
        knowledge: 'typings'
    },
    {
        name: 'Support Assistant',
        description: 'Assisting you in managing and creating tickets.',
        icon: TicketIcon,
        function: 'get_ticket,create_ticket',
        knowledge: null
    },
]

export default function EngineSelector({ onHandleEngine }) {
    const [engine, setEngine] = useState(engines[2]); // Default on Knowledge Guy
    onHandleEngine(engine); // Give back to parent the first element

    const handleEngine = (value) => {
        setEngine(value);
        onHandleEngine(value);
    };

    return (
        <div className="fixed w-full p-3.5 z-10 max-sm:-top-1.5">
            <Popover className="relative max-sm:float-right">
                {({ open }) => (
                    <>
                        <Popover.Button
                            className={`
                ${open ? 'text-gray-500 sm:text-black' : 'text-gray-500 sm:text-gray-800'}
                group inline-flex items-center rounded-md bg-gray-100 sm:px-3 py-2 text-base font-medium hover:text-black focus:outline-none focus-visible:bg-white`}
                        >
                            {engines.map((item) => (
                                <React.Fragment key={item.name}>
                                    {item.name === engine.name && (
                                        <item.icon aria-hidden="true" className="w-5 sm:text-gray-600 group-hover:animate-pulse" />
                                    )}
                                </React.Fragment>
                            ))}
                            <span className="pl-2 max-sm:hidden">{engine.name}</span>
                            <ChevronDownIcon
                                className={`ml-2 h-5 w-5 transition duration-150 text-bold ease-in-out group-hover:text-black`}
                                aria-hidden="true"
                            />
                        </Popover.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel className="fixed max-sm:-left-0 max-sm:p-3 z-10 sm:mt-3 sm:px-0 lg:max-w-xl">
                                {({ close }) => (
                                <div className="overflow-hidden rounded-md shadow-lg ring-1 ring-black/5">
                                    <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-1">
                                        {engines.map((item, index) => (
                                            <button
                                                key={index}
                                                onClick={() => {handleEngine(item); close();}}
                                                className="-m-3 z-30 flex items-center rounded-md p-2 transition duration-150 ease-in-out hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-black"
                                            >
                                                <div className="flex h-10 w-10 shrink-0 items-center justify-center text-gray-600 sm:h-12 sm:w-12">
                                                    <item.icon className="h-6 w-6" aria-hidden="true" />
                                                </div>
                                                <div className="ml-4 text-left">
                                                    <div className="text-sm font-medium text-gray-900 block">
                                                        {item.name}
                                                    </div>
                                                    <div className="text-sm text-gray-500 block">
                                                        {item.description}
                                                    </div>
                                                </div>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                                )}
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>
        </div>
    )
}