import { PaperClipIcon } from '@heroicons/react/20/solid'
import axios from 'axios';
import { toast } from 'react-toastify';

export default function TicketInput({ id, user, handleNewReply }) {
    const handleReply = async (e) => {
        e.preventDefault();
        const commentText = e.target.comment.value

        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/ticket/CreateReply', 
            {
                "message": commentText
            }, 
            {
                headers: {
                    'Authorization': 'Bearer ' + user['accessToken'],
                    'accept': 'application/json'
                },
                params: {
                    id: id
                },
                withCredentials: false,
            });

            // Empty comment box
            document.getElementById('comment').value = '';

            toast.success("Successfully saved your comment!", {});

            // Update conversations
            handleNewReply(response.data);
        } catch (error) {
            console.log(error)
            toast.error("An error occured when sending your commment, please try again.", {})
        }
    }

    return (
        <form className="relative" onSubmit={handleReply}>
            <div className="overflow-hidden border-b border-t rounded-lg border shadow-sm bg-white border-gray-200 focus-within:border-gray-400 focus-within:ring-1 focus-within:ring-gray-400">
                <textarea
                    rows={6}
                    name="comment"
                    id="comment"
                    className="block w-full resize-none border-0 py-0 m-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 outline-none"
                    placeholder="Add a comment.."
                    defaultValue={''}
                />
                <div aria-hidden="true">
                    <div className="py-2">
                        <div className="h-9" />
                    </div>
                    <div className="h-px" />
                </div>
            </div>

            <div className="absolute inset-x-px bottom-0">
                <div className="flex items-center justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
                    <div className="flex">
                        <button
                            type="button"
                            className="group -my-2 -ml-2 inline-flex items-center rounded-full px-3 py-2 text-left text-gray-400"
                        >
                            <PaperClipIcon className="-ml-1 mr-2 h-5 w-5 group-hover:text-gray-500" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="flex-shrink-0">
                        <button
                            type="submit"
                            className="inline-flex items-center rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Send comment
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}


