import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'

export default function TicketConversation({ conversations }) {
    return (
        <div>
            {conversations.map((conversation, index) => (
                <div key={index}>
                    {conversation.private === false || conversation?.private === undefined || conversation?.private === null
                        ? <div key={conversation?.id} className="border-b border-t border-gray-200 bg-white shadow-sm rounded-lg sm:border mb-2">
                            <div className="flex items-center border-b border-gray-200 p-4 grid-cols-1 sm:gap-x-6 sm:p-6">
                                <div className="grid grid-cols-1">
                                    <div className="grid grid-cols-[max-content_1fr] grid-rows-2 items-center">
                                        <div className="row-span-2 mr-3 inline-flex items-center justify-center w-10 h-10 text-md bg-red-200 rounded-lg border-gray-200 border-1 text-gray-800 font-thin">
                                            {conversation?.from_email?.match(/"([^"]+)"/)?.[1]?.substring(0, 1) ?? 'U'}
                                        </div>
                                        <div id='ticketDescription' className='w-full font-medium text-sm text-sky-600'>
                                            {conversation?.from_email?.match(/"([^"]+)"/)?.[1] ?? 'Unknown'}
                                        </div>
                                        <div id='ticketDescription' className='text-gray-700 font-normal text-xs'>
                                            {conversation?.created_at}
                                        </div>
                                    </div>
                                    <div id='ticketDescription' className='w-full mt-6 text-sm' dangerouslySetInnerHTML={{ __html: conversation?.body }}></div>
                                    {conversation?.attachments.length > 0
                                        ? <div>
                                            <div className='w-full mt-6 font-medium text-sm'>
                                                Attachments
                                            </div>
                                            <div className='flex mt-2'>
                                                {conversation?.attachments.map((attachment, index) => (
                                                    <a key={index} className='group bg-gray-700 inline-block w-16 h-16 rounded-md relative mr-2' href={attachment.attachment_url} target="_blank" rel="noreferrer">
                                                        <img className='object-cover w-16 h-16 rounded-md mr-2 group-hover:opacity-40' src={attachment.attachment_url} alt={attachment.name} />
                                                        <ArrowTopRightOnSquareIcon className='absolute inset-0 m-auto w-5 h-5 text-gray-200 invisible group-hover:visible' />
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
            ))}
        </div>
    )
}

