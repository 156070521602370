import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { SparklesIcon } from '@heroicons/react/24/outline'
import ReactMarkdown from "react-markdown";
import { ClipboardIcon } from '@heroicons/react/24/outline';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import {
    atomDark
} from 'react-syntax-highlighter/dist/cjs/styles/prism'
import { toast } from 'react-toastify';

export default function Info({ title, content, button_text, button_action, cancel_action, chat_id }) {
    const [open, setOpen] = useState(true)

    const cancelButtonRef = useRef(null)

    const cancel = async (event) => {
        setOpen(false);
        cancel_action(false);
    }

    function handleCopy(message) {
        navigator.clipboard.writeText(message);
        toast.info("Copied to clipboard!", {})
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={cancel}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-sky-100">
                                        <SparklesIcon className="h-6 w-6 text-sky-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            {title}
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-600">
                                                {content.length === 0 ? "Thinking..." :
                                                    <ReactMarkdown
                                                        className={`space-y-5 break-words ${content === '●' ? 'text-gray-100' : 'text-gray-600'}`}
                                                        children={content}
                                                        components={{
                                                            ul: ({ node, ...props }) => (<ul className="block list-disc list-outside text-left" {...props} />),
                                                            ol: ({ node, ...props }) => (<ul className="block list-decimal list-outside text-left" {...props} />),
                                                            li: ({ node, ...props }) => (<li className="marker:text-gray-400 marker:font-heading marker:text-md pl-1 text-left" {...props} />),
                                                            code({ node, inline, className, children, ...props }) {
                                                                const match = /language-(\w+)/.exec(className || '')
                                                                return !inline && match ? (
                                                                    <div className="bg-gray-800 rounded-md my-5 overflow-x-auto">
                                                                        <div className="flex text-gray-200 rounded-t-md font-heading text-xs pt-1.5 pb-0.5 px-3 justify-between">
                                                                            <div className="text-start mr-auto">
                                                                                {match[1]}
                                                                            </div>
                                                                            <button onClick={() => handleCopy(String(children).replace(/\n$/, ''))} className="flex">
                                                                                <ClipboardIcon className="w-3.5 h-full text-end" />
                                                                                <div className="ml-1 text-end">
                                                                                    Copy code
                                                                                </div>
                                                                            </button>
                                                                        </div>
                                                                        <SyntaxHighlighter
                                                                            children={String(children).replace(/\n$/, '')}
                                                                            language={match[1]}
                                                                            style={atomDark}
                                                                            PreTag="div"
                                                                            className="mockup-code bg-black text-xs rounded-b-md scrollbar-thin scrollbar-track-base-content/5 scrollbar-thumb-base-content/40 scrollbar-track-rounded-md scrollbar-thumb-rounded"
                                                                            showLineNumbers={true}
                                                                            useInlineStyles={true}

                                                                            {...props}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <code className="text-xs  inline-flex text-left items-center space-x-4 bg-neutral-800 text-blue-300 rounded-md px-1.5 py-0.5" {...props}>
                                                                        {children}
                                                                    </code>
                                                                )
                                                            },
                                                        }}
                                                    />
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-3">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 sm:col-start-3"
                                        onClick={() => button_action()}
                                    >
                                        {button_text}
                                    </button>
                                    <a
                                        className="mt-3 sm:mt-0 inline-flex w-full justify-center rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 sm:col-start-2"
                                        href={"/chat/" + chat_id}
                                    >
                                        Chat further
                                    </a>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                        onClick={() => cancel()}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}



