import { Fragment, useState, useEffect } from 'react'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { HandRaisedIcon, ShieldExclamationIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'
import axios from 'axios';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example({ onSearchChange, user }) {
  const [query, setQuery] = useState('')
  const [open, setOpen] = useState(true)
  const [items, setItems] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/cache/ticket/GetTickets', {
          headers: {
            'Authorization': 'Bearer ' + user['accessToken'],
            'accept': 'application/json'
          },
          params: {
            exclude_company_tickets: false
          },
          withCredentials: false,
        });
        setItems(response.data?.tickets);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [user]);

  const handleSearchClose = (e) => {
    setOpen(false);
    onSearchChange(false);
  };

  const filteredItems =
    query === ''
      ? []
      : items.filter((item) => {
        return item.subject.toLowerCase().includes(query.toLowerCase())
      })

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')} appear>
      <Dialog as="div" className="relative z-50" onClose={handleSearchClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-slate-900/25 backdrop-blur transition-opacity opacity-100" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto mt-10 p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden bg-white transition-all rounded-lg shadow-xl ring-1 ring-black ring-opacity-5">
              <Combobox onChange={(item) => (window.location = '/tickets/' + item.id)}>
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm outline-none"
                    placeholder="Search..."
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </div>

                {filteredItems.length > 0 && (
                  <Combobox.Options static className="max-h-96 transform-gpu scroll-py-3 overflow-y-auto p-3">
                    {filteredItems.map((item) => (
                      <Combobox.Option
                        key={item.id}
                        value={item}
                        href={"/tickets/" + item.id}
                        className={({ active }) =>
                          classNames('flex cursor-default select-none rounded-xl p-3', active && 'bg-gray-100')
                        }
                      >
                        {({ active }) => (
                          <>
                            <div
                              className={classNames(
                                'flex h-10 w-10 flex-none items-center justify-center rounded-lg',
                                item.color
                              )}
                            >
                              {item.support_type === 'Incident' ? <ShieldExclamationIcon title={item.support_type} className='w-8' /> : <HandRaisedIcon title={item.support_type} className='w-8' />}
                            </div>
                            <div className="ml-4 flex-auto">
                              <p
                                className={classNames(
                                  'text-sm font-medium',
                                  active ? 'text-gray-900' : 'text-gray-700'
                                )}
                              >
                                {item.subject}
                              </p>
                              <p className={classNames('text-sm', active ? 'text-gray-700' : 'text-gray-500')}>
                                {item.priority_label + " Priority"}
                              </p>
                              <p className={classNames('text-sm', active ? 'text-gray-700' : 'text-gray-500')}>
                                {item.created_at.slice(0, 10)}
                              </p>
                            </div>
                            <div
                              className={classNames(
                                'flex h-10 w-10 pr-5 sm:pr-7 flex-none items-center justify-center rounded-lg',
                                item.color
                              )}
                            >
                              <span className={`items-center justify-center rounded-md border bg-white py-1 px-3 text-sm font-medium max-sm:hidden ${item.stats === 4 || item.status === 5 ? 'border-green-600 text-green-600' : 'border-purple-600 text-purple-600'}`}>
                                {item.status_label}
                              </span>
                            </div>
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}

                {query !== '' && filteredItems.length === 0 && (
                  <div className="px-6 py-14 text-center text-sm sm:px-14">
                    <ExclamationCircleIcon
                      type="outline"
                      name="exclamation-circle"
                      className="mx-auto h-6 w-6 text-gray-400"
                    />
                    <p className="mt-4 font-semibold text-gray-900">No tickets found</p>
                    <p className="mt-2 text-gray-500">No tickets found for this search term. Please try again.</p>
                  </div>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
