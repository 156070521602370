import React from 'react';
import { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Login from './pages/login'
import Loading from './pages/loading'
import NotFound from './pages/notfound'
import Chat from './pages/chat'
import Profile from './pages/profile'
import Tickets from './pages/tickets'
import Ticket from './pages/ticket'
import TicketForm from './pages/newticket'
import Dashboard from './pages/dashboard'

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const subscribe = onAuthStateChanged(auth, (authUser) => {
      setUser(authUser);
      setLoading(false);
    });

    return () => subscribe();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="App">
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Navigate to="/chat" replace />} />
        <Route path="/chat" element={user ? <Chat user={user} /> : <Login /> } />
        <Route path="/chat/:id" element={user ? <Chat user={user} /> : <Login /> } />
        <Route path="/profile" element={user ? <Profile user={user} /> : <Login /> } />
        <Route path="/tickets" element={user ? <Tickets user={user} /> : <Login /> } />
        <Route path="/tickets/:id" element={user ? <Ticket user={user} /> : <Login /> } />
        <Route path="/tickets/new" element={user ? <TicketForm user={user} /> : <Login /> } />
        <Route path="/dashboard" element={user ? <Dashboard user={user} /> : <Login /> } />
      </Routes>
    </div>
  );
}

export default App;