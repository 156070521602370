import Cookies from 'js-cookie';
import { signOut } from "firebase/auth";

import { auth } from "../components/Firebase";
import Layout from '../layout/Layout';

export default function Profile({ user }) {
    async function handleLogout(e) {
        await signOut(auth);
        Cookies.remove('token');
        window.location.reload();
    }

    return(
        <Layout user={user}>
            <div className='p-5 sm:px-10'>
                <div className="py-5 sm:py-10">
                    <div className="mx-auto sm:px-2 lg:px-8">
                        <div className="mx-auto lg:px-0">
                            <h1 className='text-neutral-1 text-2xl md:text-4xl font-bold font-heading'>Profile</h1>
                            <p className="mt-2 text-sm text-gray-500">
                            Logged in as {user.email}
                            </p>
                        </div>
                    </div>
                    <div className="mt-16 sm:px-2 lg:px-8">
                        <button className="bg-black rounded-lg text-white p-3 hover:bg-gray-700 focus:ring-2 focus:outline-none focus:ring-gray-400" onClick={handleLogout}>Log out</button>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
