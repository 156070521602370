import React, { useEffect } from "react";

import LoginForm from '../components/LoginForm';
import { ReactComponent as SupportDeskLogo } from '../assets/supportdesk.svg';

export default function Login() {
    useEffect(() => {
        document.title = "Login | New Black Support";  
    }, []);

    return(
        <section className="bg-gray-100 dark:bg-gray-900 h-[100dvh] sm:h-screen">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-full lg:py-0">
                <div className="p-10 inline-flex justify-center">
                    <SupportDeskLogo className="text-black w-8/12" />
                </div>
                <LoginForm/>
                <div className="p-10"></div>
            </div>
        </section>
 );
}