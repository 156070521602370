import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDlzE38eepemDaxfWnNnzO-aSfno9qa-r0",
    authDomain: "nb-docs.firebaseapp.com",
    projectId: "nb-docs",
    storageBucket: "nb-docs.appspot.com",
    messagingSenderId: "962476317005",
    appId: "1:962476317005:web:b8cdac03a28dceca683f04",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
