import Sidebar from '../components/Sidebar';

export default function Layout({children, user, newMessage}) {
  return (
    <div>
      <main id="main-content" className='flex flex-col transition-transform duration-500 sm:ms-72 bg-gray-100 h-[100dvh] sm:h-screen overflow-auto'>
        <Sidebar user={user}  newMessage={newMessage} />
        {children}
      </main>
    </div>
  )
}