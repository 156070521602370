import { NavLink, useLocation } from 'react-router-dom';
import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { TrashIcon, MagnifyingGlassIcon, PencilSquareIcon, DocumentTextIcon, CloudArrowDownIcon, CodeBracketSquareIcon, ChartBarSquareIcon } from '@heroicons/react/24/outline'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import { ReactComponent as SupportDeskLogo } from '../assets/supportdesk.svg';
import { ReactComponent as ProfileIcon } from '../assets/profile.svg';
import { ReactComponent as MenuIcon } from '../assets/menu.svg';
import { ChatBubbleOvalLeftEllipsisIcon as ChatIcon, TicketIcon } from '@heroicons/react/24/outline';


import Alert from './Alert';
import Search from './Search';

export default function Sidebar({ user, newMessage }) {
    const location = useLocation();
    const [conversations, setConversations] = useState([]);
    const [alert, setAlert] = useState(false);
    const [search, setSearch] = useState(false);
    const [chat, setChat] = useState(null);
    const [chatId, setChatId] = useState(null);
    const [executedAlert, setExecutedAlert] = useState(null);

    function openSidebar(mobile) {
        if (mobile === true) {
            document.getElementById('sidebar').style.marginLeft = "18rem";
        }
        else {
            document.getElementById('sidebar').style.marginLeft = "0";
            document.getElementById('toggle-sidebar').style.left = "18rem";
            document.getElementById('close-sidebar').style.display = "inline";
            document.getElementById('open-sidebar').style.display = "none";
            document.getElementById('main-content').style.marginLeft = "18rem";
        }
    }

    function closeSidebar(mobile) {
        if (mobile === true) {
            document.getElementById('sidebar').style.marginLeft = "0";
        }
        else {
            document.getElementById('sidebar').style.marginLeft = "-18rem";
            document.getElementById('toggle-sidebar').style.left = "0";
            document.getElementById('close-sidebar').style.display = "none";
            document.getElementById('open-sidebar').style.display = "inline";
            document.getElementById('main-content').style.marginLeft = "0";
        }
    }

    const onHandleDelete = (e) => {
        try {
            e.preventDefault();
            var chatId = e.target.closest('button').id;
            var chatName = e.target.closest('button').name;
            setChatId(chatId);
            setChat(chatName);
            setAlert(e);
        }
        catch (error) {
            console.log(error);
            setAlert(false);
        }
    }

    const onHandleSearch = (e) => {
        try {
            e.preventDefault();
            setSearch(e);
        }
        catch (error) {
            console.log(error);
            setSearch(false);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/chatbot/GetConversations', {
                    headers: {
                        'Authorization': 'Bearer ' + user['accessToken'],
                        'accept': 'application/json'
                    },
                    withCredentials: false,
                });
                //var result = [{ "id": crypto.randomUUID(), "title": "New Chat +" }]
                var result = [{ "id": "new", "title": "New Chat" }]
                result = [...result, ...response.data.conversations];
                setConversations(result);
            } catch (error) {
                if (error?.response?.status === 401 && error?.response?.statusText === "Unauthorized") {
                    window.location.reload(false);
                    toast.info("Reloaded the page because your login token was exipred.", {})
                }
                setConversations([{ "id": "new", "title": "New Chat" }]);
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [user, newMessage, executedAlert]);

    const sidebarItems = [
        { path: '/chat', label: 'Chat', icon: ChatIcon, children: conversations },
        { path: '/tickets', label: 'Tickets', icon: TicketIcon, righticon: MagnifyingGlassIcon, function: onHandleSearch },
        { path: 'https://docs.newblack.io/', label: 'Docs', icon: DocumentTextIcon },
        { path: 'https://docs.newblack.io/release-notes', label: 'Drops', icon: CloudArrowDownIcon },
        { path: 'https://dora.on-eva.io/', label: 'DORA', icon: CodeBracketSquareIcon },
        { path: '/dashboard', label: 'Dashboard', icon: ChartBarSquareIcon },
        { path: '/profile', label: 'Profile', icon: ProfileIcon },
    ];

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <div className='bg-gray-100 mb-12 sm:mb-0'>
            {alert
                ? <Alert
                    title={"Delete chat?"}
                    content={"This will delete " + chat}
                    button={"Delete"}
                    onDeleteChange={onHandleDelete}
                    functionName="chat"
                    user={user}
                    chatId={chatId}
                    setExecutedAlert={setExecutedAlert}
                />
                : null}

            {search
                ? <Search onSearchChange={onHandleSearch} user={user} />
                : null}

            <button onClick={() => openSidebar(true)} className="fixed inline-flex items-center z-20 p-2 mt-1.5 ms-3 text-sm text-gray-500 rounded-md sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <MenuIcon className="mw-6 h-6" />
            </button>
            <div className="sm:hidden fixed bg-gray-100 border-b-2 border-b-gray-200 z-10 inline-flex overflow-x-hidden text-gray-500 w-full p-3.5 justify-center font-heading">
                {sidebarItems.find(item => item.path === location.pathname)?.label || "New Black Support"}
            </div>

            <aside id="sidebar" className="fixed top-0 left-0 z-40 w-72 h-screen transition-transform duration-500 -translate-x-full sm:translate-x-0">
                <div className="h-full px-3 py-4 overflow-y-auto bg-black dark:bg-gray-50">
                    <span className="text-white text-xl fixed top-0 right-0 py-7 px-6 sm:hidden" onClick={() => closeSidebar(true)}>×</span>
                    <SupportDeskLogo className="ms-1 text-white p-1 pb-5 w-8/12" />
                    <ul className="space-y-2 mt-3 font-light">
                        {sidebarItems.map(item => (
                            <li key={item.path}>
                                {!item.children ? (
                                    <NavLink exact to={item.path} className={({ isActive }) => `flex items-center px-2 py-2 text-white rounded-md dark:text-black hover:bg-gray-600 dark:hover:bg-gray-700 ${isActive ? 'bg-gray-800' : ''}`}>
                                        <item.icon className="ms-1 w-6 h-7" />
                                        <span className="ms-7">{item.label}</span>
                                        {item.righticon ? (
                                            <button onClick={item.function} className="absolute ms-1 w-4 h-4 text-gray-300 right-6">
                                                <item.righticon />
                                            </button>
                                        ) : null}
                                    </NavLink>
                                ) : (
                                    <Disclosure as="div">
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button
                                                    className={classNames(
                                                        item.current ? 'bg-gray-800' : 'hover:bg-gray-600',
                                                        'flex items-center w-full px-2 py-2 text-white rounded-md dark:text-black hover:bg-gray-600 dark:hover:bg-gray-700'
                                                    )}
                                                >
                                                    <item.icon className="ms-1 h-7 w-6 shrink-0 text-white" aria-hidden="true" />
                                                    <span className="ms-7">{item.label}</span>
                                                    <ChevronRightIcon
                                                        className={classNames(
                                                            open ? 'rotate-90 text-gray-400' : 'text-gray-300',
                                                            'ml-auto h-5 w-5 shrink-0'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                </Disclosure.Button>
                                                <Disclosure.Panel as="ul" className="mt-1 px-4">
                                                    {item.children.map((conversation) => (
                                                        <li key={conversation.id}>
                                                            <NavLink exact to={conversation.title === "New Chat" ? "/" : "/chat/" + conversation.id} className={({ isActive }) => `group flex leading-5 items-center pl-9 pr-2 py-2 text-sm text-white rounded-md dark:text-black hover:bg-gray-600 dark:hover:bg-gray-700 ${isActive ? 'bg-gray-800' : ''}`}>
                                                                {({ isActive }) => (
                                                                    <span className="relative grow overflow-hidden whitespace-nowrap">
                                                                        {conversation.title}
                                                                        <div className={` ${isActive ? 'from-gray-800' : 'from-black'} absolute m-auto bottom-0 right-0 top-0 bg-gradient-to-l to-transparent w-8 from-0% group-hover:from-gray-600 group-hover:from-60%`}></div>
                                                                        <button id={conversation.id} name={conversation.title} onClick={conversation.title === "New Chat" ? null : onHandleDelete} className='absolute m-auto w-4 h-4 bottom-0 right-0 top-0 group-hover:block hidden'>
                                                                            {conversation.title === "New Chat" ? <PencilSquareIcon className='text-gray-300' /> : <TrashIcon className='text-gray-300' />}
                                                                        </button>
                                                                    </span>
                                                                )}
                                                            </NavLink>
                                                        </li>
                                                    ))}
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </aside>

            <div id="toggle-sidebar" className="group fixed left-72 ml-1 top-1/2 z-10 max-sm:hidden transition-transform duration-500">
                <button id="close-sidebar" onClick={() => closeSidebar(false)}>
                    <div className="flex h-6 w-6 flex-col items-center">
                        <div className='h-3 w-1 rounded-full bg-gray-400 group-hover:bg-black translate-y-0.5 group-hover:rotate-12'></div>
                        <div className='h-3 w-1 rounded-full bg-gray-400 group-hover:bg-black transform group-hover:-rotate-12'></div>
                    </div>
                </button>
                <button id="open-sidebar" className="hidden" onClick={() => openSidebar(false)}>
                    <div className="flex h-6 w-6 flex-col items-center">
                        <div className='h-3 w-1 rounded-full bg-gray-400 group-hover:bg-black translate-y-0.5 group-hover:-rotate-12'></div>
                        <div className='h-3 w-1 rounded-full bg-gray-400 group-hover:bg-black transform group-hover:rotate-12'></div>
                    </div>
                </button>
            </div>
        </div>
    )
}

