import { SparklesIcon } from '@heroicons/react/24/outline';

const suggestions = [
    'How to differentiate between Sales order and Interstore order?',
    'Does CreateStockMutationReason has optional fields',
    'Can you explain how I can add a new reason for stock adjustments?',
    'How can I add a new reason for stock corrections?',
    'Can you show a sample JSON for CreateStockMutationReason',
    'I want to enable a new payment method for some of the stores, how can I do that?',
];

export default function ChatOverlay({ setSuggestionChatText, engineName }) {
    
    const handleSuggestionChange = (event) => {
        setSuggestionChatText(event.target.value);
    }

    return (
        <div className="flex-1 overflow-hidden">
            <div className="overflow-y-auto flex items-center justify-center h-full m-auto">
                <div className="h-full md:h-fit">
                    <div className="flex flex-col items-center justify-center flex-grow p-4">
                        <div className='flex mb-4 md:mb-8'>
                            <h1 className="text-2xl sm:text-5xl font-bold font-heading text-center">EVA Support AI</h1>
                            <SparklesIcon className='ml-2 w-6 md:w-10 animate-pulse' />
                        </div>
                        <div className='flex mb-6 md:mb-8 text-center'>
                            <span className="text-neutral-1 font-heading text-md">You can select an engine that best suits your question, the current selected engine is <b>{engineName}</b>.</span>
                        </div>
                        <span className="text-neutral-1 text-base font-bold mb-4">Example questions</span>
                        <div className="columns-1 lg:columns-2 max-w-2xl gap-4">
                            {suggestions.slice(0, window.innerWidth <= 640 ? 3 : suggestions.length).map((text, index) => (
                                <div key={index} className="mb-4 w-full">
                                    <button type="button" value={text} onClick={ handleSuggestionChange } className="outline-none p-3 sm:p-4 bg-white rounded-md border text-sm sm:text-md border-solid border-neutral-4 hover:bg-gray-50 hover:border-gray-400 w-full">
                                        {text} →
                                    </button>
                                </div>
                            ))}
                        </div>
                        <span className="text-neutral-1 text-base font-bold mb-4">Platform status</span>
                        <div className='flex mb-6 md:mb-8 text-center'>
                            <a href="https://newblack.freshstatus.io" id="freshstatus-badge-root"
                                data-banner-style="highlighted">
                                <img src="https://public-api.freshstatus.io/v1/public/badge.svg/?badge=4b1ce8b0-18f7-4e75-9fa1-6fd3aa654fc3" alt="statusicon" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


