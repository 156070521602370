import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer, Bounce, toast } from 'react-toastify';

import App from './App';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <ToastContainer
      toastClassName={() => "text-sm font-sans bg-white dark:bg-zinc-800 button:invert text-zinc-800 dark:text-zinc-200 p-3 flex mb-2 pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"}
      hideProgressBar={true}
      transition={Bounce}
      position={toast.POSITION.TOP_RIGHT}
      autoClose={3000}
    />
    <Router>
      <App />
    </Router>
  </>
);
