import React, { useState, useEffect } from 'react';

import { ReactComponent as SendIcon } from '../assets/send.svg';

export default function ChatInput({ onUserMessage, currentMessage, loading }) {
    const [message, setMessage] = useState(currentMessage);

    useEffect(() => {
        setMessage(currentMessage);
    }, [currentMessage]);

    const handleMessage = (event) => {
        setMessage(event.target.value);
    };

    const handleSend = (event) => {
        event.preventDefault();

        if (onUserMessage) {
            onUserMessage(message);
        }

        console.log('Message received: ' + message)
        document.getElementById('user-message').value = '';
        setMessage('');
    };

    return (
        <div className="w-full shrink-0 pt-2 sm:pt-5 flex justify-center" >
            <div className="max-w-3xl sm:px-6 lg:px-8 px-4 w-full mx-auto">
                <form id='chat-input' onSubmit={handleSend}>
                    <div className="flex items-end gap-4 w-full bg-white py-2 px-4 rounded-md border border-solid border-neutral-4 cursor-text focus-within:border-gray-700 focus-within:border">
                        <input id="user-message" name="query" rows="1" placeholder="Send a message" className="m-0 w-full bg-white max-h-[200px] resize-none border-0 outline-none" value={message} onChange={handleMessage}></input>
                        <div className='flex m-auto'>
                            <button id='chat-input-button' type="submit" disabled={!message.trim() || loading} className={`outline-none ${!message.trim() ? 'cursor-not-allowed' : ''} shrink-0`}>
                                {loading
                                    ? <div className="border-gray-300 h-5 w-5 animate-spin rounded-full border-4 border-t-gray-400" />
                                    : <SendIcon className={`w-6 h-6 t ${!message.trim() ? 'text-gray-400' : 'text-black'}`} />}
                            </button>
                        </div>
                    </div>
                </form>
                {/* <div className='flex items-center gap-5 max-sm:mb-1 sm:py-2 px-2 text-gray-400'>
                    <a href='https://docs.newblack.io/' target="_blank" rel="noreferrer" className='hover:text-gray-600 text-xs md:text-sm'>
                        Documentation
                    </a>
                    <a href='https://docs.newblack.io/release-notes' target="_blank" rel="noreferrer" className='hover:text-gray-600 text-xs md:text-sm'>
                        Drops
                    </a>
                    <a href='https://dora.on-eva.io/' target="_blank" rel="noreferrer" className='hover:text-gray-600 text-xs md:text-sm'>
                        DORA
                    </a>
                    <div className='flex-1 text-right'>
                        <a href='https://www.newblack.io/' target="_blank" rel="noreferrer" className='ml-auto hover:text-gray-600 text-xs md:text-sm max-sm:hidden'>
                            Copyright © {new Date().getFullYear()} New Black BV
                        </a>
                        <a href='https://www.newblack.io/' target="_blank" rel="noreferrer" className='ml-auto hover:text-gray-600 text-xs md:text-sm sm:hidden'>
                            New Black ©
                        </a>
                    </div>
                </div> */}
                <div class="flex flex-col items-center gap-5 py-1 sm:py-2 px-2 text-zinc-500 w-full">
                    <span class="text-xs text-center">The AI's answers may contain inaccuracies; verify critical information and cross-check with documentation.</span>
                </div>
            </div>
        </div>
    )
}


