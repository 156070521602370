import { PlusIcon } from '@heroicons/react/20/solid';

import Layout from '../layout/Layout';
import TicketList from '../components/TicketList';

export default function Tickets({ user }) {
    return (
        <Layout user={user}>
            <div className='p-5 sm:px-10'>
                <div className="py-5 sm:py-10">
                    <div className="flex mx-auto sm:px-2 lg:px-8">
                        <div className="justify-start lg:px-0">
                            <h1 className='text-neutral-1 text-2xl md:text-4xl font-bold font-heading'>Support Tickets</h1>
                            <p className="mt-2 text-sm text-gray-500">
                                Check the status of your support tickets.
                            </p>
                        </div>
                        <div className="ml-auto lg:px-0">
                            <a href="tickets/new" type="submit" className="inline-flex font-heading font-bold items-center rounded-md bg-black m-2 px-2 py-2 sm:px-4 sm:py-3 text-sm md:text-md text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                <div className='flex'>
                                    <PlusIcon className='text-white w-4' />
                                    <span className='sm:ml-1 mr-1 sm:mr-0'>New</span>
                                    <span className='hidden sm:block sm:ml-1'>support ticket</span>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="mt-8 sm:mt-16 sm:px-2 lg:px-8">
                        <TicketList user={user} />
                    </div>
                </div>
            </div>
        </Layout >
    );
}
