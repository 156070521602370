export default function TicketDetails({ data }) {
    return (
        <div className="border-b border-t border-gray-200 bg-white shadow-sm rounded-lg sm:border">
            <div className="flex items-center border-b border-gray-200 p-4 sm:p-6">
                <div className='grid grid-cols-1 w-full'>
                    <h2 className='font-heading font-bold text-xl mb-2'>Ticket Details</h2>
                    <div className='text-sm my-4'>
                        <div className='font-medium'>
                            Status
                        </div>
                        <div className='font-light'>
                            {data.status_label}
                        </div>
                    </div>
                    <hr className="my-2 w-full border-t-1 bg-gray-700" />
                    <div className='text-sm my-2'>
                        <div className='font-medium'>
                            Number
                        </div>
                        <div className='font-light'>
                            {data.id}
                        </div>
                    </div>
                    <div className='text-sm my-2'>
                        <div className='font-medium'>
                            Type
                        </div>
                        <div className=' font-light'>
                            {data.custom_fields?.cf_support_type}
                        </div>
                    </div>
                    <div className='text-sm my-2'>
                        <div className='font-medium'>
                            Priority
                        </div>
                        <div className='font-light'>
                            {data.priority_label}
                        </div>
                    </div>
                    <div className='text-sm my-2'>
                        <div className='font-medium'>
                            Topic
                        </div>
                        <div className='font-light'>
                            {data.custom_fields?.cf_topic750041}
                        </div>
                    </div>
                    <hr className="my-2 w-full border-t-1 bg-gray-700" />
                    <div className="mt-4">
                        <a
                            href={"/tickets"}
                            className="flex items-center justify-center rounded-md border border-gray-300 bg-gray-50 px-2.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                        >
                            <span>Close</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}


