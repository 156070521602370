import React from 'react';

export default function Loading() {
    return (
        // <div class="border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-gray-700" />
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-100 flex flex-col items-center justify-center">
            <div className="border-gray-300 h-12 w-12 mb-5 animate-spin rounded-full border-4 border-t-gray-700" />
            <h2 className="text-center text-xl text-gray-700 font-heading">Loading...</h2>
            <p className="w-1/3 text-center text-gray-400 font-heading">This may take a few seconds, please don't close this page.</p>
        </div>
    );
}

