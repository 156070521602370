import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon, HandRaisedIcon, ShieldExclamationIcon } from '@heroicons/react/24/outline'

export default function TicketList({ user }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/ticket/GetTickets', {
                    headers: {
                        'Authorization': 'Bearer ' + user['accessToken'],
                        'accept': 'application/json'
                    },
                    params: {
                        exclude_company_tickets: false
                    },
                    withCredentials: false,
                });
                setData(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [user]);

    return (
        <div className="mx-auto">
            {loading ? (
                <p className='text-gray-500'>Loading tickets...</p>
            ) : (
                <div>
                    {data.tickets && data.tickets.length > 0 ? (
                        <div className="mx-auto space-y-4 sm:px-4 lg:px-0">
                            {data.tickets.map((ticket) => (
                                <div key={ticket.id} className="border-b border-t border-gray-200 bg-white shadow-sm rounded-lg sm:border">
                                    <div className="flex items-center border-b border-gray-200 p-4 grid-cols-6 sm:gap-x-6 sm:p-6">
                                        <div className="hidden sm:flex sm:grow-0 ml-2 w-10">
                                            {ticket.support_type === 'Incident' ? <ShieldExclamationIcon title={ticket.support_type} className='w-8' /> : <HandRaisedIcon title={ticket.support_type} className='w-8' />}
                                        </div>
                                        <dl className="grid flex-1 grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-5">
                                            <div className='sm:col-span-2'>
                                                <dt className="font-bold text-gray-900">Subject</dt>
                                                <dd className="mt-1 text-gray-900">{ticket.subject}<span className='text-gray-500'>{" #" + ticket.id}</span></dd>
                                                <dd className="mt-1 text-gray-500">{ticket.priority_label + " Priority"}</dd>
                                            </div>
                                            <div className="sm:col-span-1 hidden sm:block">
                                                <dt className="font-medium text-gray-900">Created</dt>
                                                <dd className="mt-1 text-gray-500">
                                                    <time dateTime={ticket.created_at}>{ticket.created_at}</time>
                                                </dd>
                                            </div>
                                            <div className='sm:col-span-1'>
                                                <dt className="font-medium text-gray-900">Status</dt>
                                                <dd className='mt-2 flex'>
                                                    <span className={`items-center justify-center rounded-md border bg-white py-1 px-3 text-sm font-medium ${ticket.status === 4 || ticket.status === 5 ? 'border-green-600 text-green-600' : 'border-purple-600 text-purple-600'}`}>
                                                        {ticket.status_label}
                                                    </span>
                                                </dd>
                                            </div>
                                        </dl>

                                        <Menu as="div" className="relative flex justify-end lg:hidden">
                                            <div className="flex items-center">
                                                <Menu.Button className="-m-2 flex items-center p-2 text-gray-400 hover:text-gray-500">
                                                    <span className="sr-only">Options for ticket {ticket.id}</span>
                                                    <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
                                                </Menu.Button>
                                            </div>

                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="py-1">
                                                        <Menu.Item>
                                                            <a href={"/tickets/" + ticket.id} className='text-gray-700 block px-4 py-2 text-sm'>
                                                                View
                                                            </a>
                                                        </Menu.Item>
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>

                                        <div className="hidden ml-auto lg:col-span-2 lg:flex lg:items-center lg:justify-end lg:space-x-4">
                                            <a
                                                href={"/tickets/" + ticket.id}
                                                className="flex items-center justify-center rounded-md border border-gray-300 bg-white px-2.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                            >
                                                <span>View Ticket</span>
                                                <span className="sr-only">{ticket.id}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>


                    ) : (<p className='text-gray-700'>No tickets found.</p>)}
                </div>
            )}
        </div>
    )

}




