import React, { Component } from "react";
import Chart from "react-apexcharts";

class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: [0],
      chartCategories: [0],
    };
  }

  componentDidMount() {
    this.setState({
      chartData: this.props.chartData,
      chartOptions: this.props.chartOptions,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.chartData !== this.props.chartData || prevProps.chartCategories !== this.props.chartCategories) {
      this.setState({
        chartData: this.props.chartData,
        chartCategories: this.props.chartCategories,
      });
    }
  }

  render() {
    return (
      <Chart
        options={{
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          tooltip: {
            style: {
              fontSize: "12px",
              fontFamily: 'SK-Modernist',
            },
            onDatasetHover: {
              style: {
                fontSize: "12px",
                fontFamily: 'SK-Modernist',
              },
            },
            theme: "dark",
          },
          xaxis: {
            categories: this.state.chartCategories,
            show: false,
            labels: {
              show: true,
              hideOverlappingLabels: true,
              style: {
                colors: "#636363",
                fontSize: "11px",
                fontWeight: "500",
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: true,
            color: "#636363",
            labels: {
              show: true,
              style: {
                colors: "#636363",
                fontSize: "11px",
                fontWeight: "500",
              },
              formatter: function (val) {
                return val.toFixed(0);
              }
            },
          },

          grid: {
            borderColor: "rgba(163, 174, 208, 0.3)",
            show: true,
            yaxis: {
              lines: {
                show: true,
                opacity: 0.5,
              },
            },
            row: {
              opacity: 0.5,
            },
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          fill: {
            type: "solid",
            colors: ["#141414"],
          },
          legend: {
            show: false,
          },
          colors: ["#141414"],
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 5,
              columnWidth: "80%",
            },
          },
        }}
        series={this.state.chartData}
        type='bar'
        width='100%'
        height='100%'
      />
    );
  }
}

export default BarChart;
