import { Listbox, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { HandRaisedIcon, ShieldExclamationIcon, SparklesIcon } from '@heroicons/react/24/outline'
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import Layout from '../layout/Layout';
import Info from '../components/Info';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TicketForm({ user }) {
    const [searchParams] = useSearchParams();
    const [selectedType, setSelectedType] = useState(null)
    const [selectedTopic, setSelectedTopic] = useState(null)
    const [selectedApplication, setSelectedApplication] = useState(null)
    const [selectedEnvironment, setSelectedEnvironment] = useState(null)
    const [applications, setApplications] = useState([])
    const [topics, setTopics] = useState([])
    const [environments, setEnvironments] = useState([])
    const [subject, setSubject] = useState(searchParams.get('subject'))
    const [loading, setLoading] = useState(false)
    const [answering, setAnswering] = useState(false)
    const [suggestion, setSuggestion] = useState('')
    const [suggestionApplication, setSuggestionApplication] = useState('')
    const [suggestionTopic, setSuggestionTopic] = useState('')
    const [suggestionEnvironment, setSuggestionEnvironment] = useState('')
    const [info, setInfo] = useState(false);
    const [possibleAnswer, setPossibleAnswer] = useState('');
    const [chat, setChat] = useState(null);

    const navigate = useNavigate();

    const types = [
        { id: 1, name: 'Help & Implementation', icon: HandRaisedIcon },
        { id: 2, name: 'Incident', icon: ShieldExclamationIcon },
    ]

    useEffect(() => {
        const fetchFields = () => {
            const response = axios.get(process.env.REACT_APP_API_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/ticket/GetTicketFields', {
                headers: {
                    'Authorization': 'Bearer ' + user['accessToken'],
                    'accept': 'application/json'
                },
                withCredentials: false,
            });

            response.then((result) => {
                const data = result.data;
                data.forEach(function (field) {
                    if (field.name === "Application") {
                        setApplications(field.choices)
                        setSelectedApplication(field.choices[0])
                    };
                    if (field.name === "Topic") {
                        setTopics(field.choices)
                        setSelectedTopic(field.choices[0])
                    };
                    if (field.name === "Environment") {
                        setEnvironments(field.choices)
                        setSelectedEnvironment(field.choices[0])
                    };
                });
            }).catch((error) => {
                console.error('Error fetching data:', error);
                toast.error("Error when fetching available ticket fields, please reload the page.", {})
            });
        };

        fetchFields();
    }, [user]);

    const handleSubmitTicket = async () => {
        try {
            const description = document.getElementById('description').value;
            const response = await axios.post(process.env.REACT_APP_API_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/ticket/CreateTicket',
                {
                    subject: subject,
                    description: description,
                    type: selectedType,
                    application: selectedApplication,
                    topic: selectedTopic,
                    environment: selectedEnvironment,
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + user['accessToken'],
                        'accept': 'application/json'
                    },
                    params: {},
                    withCredentials: false,
                });

            toast.success("Successfully created your support ticket.", {})

            // Clear the possible answer chat
            await axios.post(process.env.REACT_APP_API_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/chatbot/GetAnswer',
            {
                "message": "clear"
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + user['accessToken'],
                    'accept': 'application/json'
                },
                params: {
                    chat_id: chat,
                    stream: false,
                    skip_user_info: true,
                    skip_conversation_title: true
                },
                withCredentials: false,
            });

            // Navigate to ticket detail page
            navigate('/tickets/' + response.data.id)
        }
        catch (error) {
            console.error('Error creating ticket:', error);
            toast.error("An error occured when creating your ticket, please try again.", {})
        }
    };

    useEffect(() => {
        const handleTicketSuggestion = async () => {
            if (subject !== '' && subject !== null && applications.length > 0 && topics.length > 0 && environments.length > 0) {
                setLoading(true);

                // Request to get an ansewer
                try {
                    const params = {
                        stream: true,
                        skip_user_info: true,
                        skip_conversation_title: true
                    };
                    const paramsCleaned = queryString.stringify(params);

                    const response = await fetch(process.env.REACT_APP_API_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/chatbot/GetAnswer?' + paramsCleaned, {
                        method: "POST",
                        headers: {
                            'Authorization': 'Bearer ' + user['accessToken'],
                            "Content-Type": "application/json",
                            'Accept': 'text/event-stream'
                        },
                        body: JSON.stringify({ "message": 'You are given a subject on which you have to decide which application, topic, and environment it best suits.\n\nFor the application you choose one of the following values: ' + applications + '\n\nFor the topic you must choose between these values: ' + topics + '\n\nFor the environment you must choose between these values' + environments + '\n\nThe subject is "' + subject + '"\n\nYou must formulate your answer as a JSON result like this {"application":"App Suite", "topic":"AX", "environment":"test"}\nOnly answer with the JSON and nothing else.' })
                    });
                    if (!response.ok) {
                        toast.error("Something went wrong while getting an answer, please try again.", {})
                    }

                    // Here we start prepping for the streaming response
                    const reader = response.body.getReader();
                    const decoder = new TextDecoder();
                    const loopRunner = true;

                    let answer = '';
                    while (loopRunner) {
                        // Here we start reading the stream, until its done.
                        const { value, done } = await reader.read();
                        if (done) {
                            break;
                        }
                        const decodedChunk = decoder.decode(value, { stream: true });
                        const replacedChunk = decodedChunk.replace(/}{/g, '}|{')
                        const chunks = replacedChunk.split('|')
                        
                        setAnswering(true);
                        for (const chunk of chunks) {
                            // Define answer
                            const chunkMessage = JSON.parse(chunk)?.message;
                            if (chunkMessage !== undefined) {
                                // Iterate through each letter to get a typing effect
                                for (let i = 0; i < chunkMessage.length; i++) {
                                    answer += chunkMessage[i];
                                    setSuggestion(answer + "▌");
                                    await new Promise(resolve => setTimeout(resolve, 1));
                                }
                            }
                        }
                    }
                    // At the end update the complete answer without blink
                    setSuggestion(answer);
                    setLoading(false);
                    setAnswering(false);

                    const answerJson = JSON.parse(answer);
                    setSelectedApplication(answerJson?.application);
                    setSuggestionApplication(answerJson?.application);
                    setSelectedTopic(answerJson?.topic);
                    setSuggestionTopic(answerJson?.topic);
                    setSelectedEnvironment(answerJson?.environment);
                    setSuggestionEnvironment(answerJson?.environment);
        
                    // Clear the suggestion chat
                    const chatId = response.headers.get('chat_id');
                    await axios.post(process.env.REACT_APP_API_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/chatbot/GetAnswer',
                    {
                        "message": "clear"
                    },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + user['accessToken'],
                            'accept': 'application/json'
                        },
                        params: {
                            chat_id: chatId,
                            stream: false,
                            skip_user_info: true,
                            skip_conversation_title: true
                        },
                        withCredentials: false,
                    });
                }
                catch (error) {
                    console.error('Error getting answer:', error);
                    setLoading(false);
                    toast.error("An error occured when giving an answer, please try again.", {})
                }  
            }
        }

        handleTicketSuggestion();
    }, [user, applications, topics, environments, subject]);

    const handleAnswerSuggestion = async (event) => {
        event.preventDefault();

        if (selectedType === null) {
            toast.info("Please select a request type for your support ticket.", {})
        }
        else {
            setInfo(true);

            // Request to get an suggestion
            try {
                const params = {
                    stream: true,
                    skip_user_info: true,
                    skip_conversation_title: false,
                    function: 'get_information',
                    knowledge_type: null
                };
                const paramsCleaned = queryString.stringify(params);
                
                const description = document.getElementById('description').value;

                const response = await fetch(process.env.REACT_APP_API_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/chatbot/GetAnswer?' + paramsCleaned, {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + user['accessToken'],
                        "Content-Type": "application/json",
                        'Accept': 'text/event-stream'
                    },
                    body: JSON.stringify({ "message": 'What would be the solution for the following issue:\n\nType: ' + selectedType + '\n\nSubject: ' + subject + '\n\nApplication: ' + selectedApplication + '\n\nTopic: ' + selectedTopic + '\n\nEnvironment: ' + selectedEnvironment + '\n\nDescription: ' + description  })
                });
                if (!response.ok) {
                    toast.error("Something went wrong while getting an answer, please try again.", {})
                }

                // Here we start prepping for the streaming response
                const reader = response.body.getReader();
                const decoder = new TextDecoder();
                const loopRunner = true;

                let answer = '';
                while (loopRunner) {
                    // Here we start reading the stream, until its done.
                    const { value, done } = await reader.read();
                    if (done) {
                        break;
                    }
                    const decodedChunk = decoder.decode(value, { stream: true });
                    const replacedChunk = decodedChunk.replace(/}{/g, '}|{')
                    const chunks = replacedChunk.split('|')

                    for (const chunk of chunks) {
                        // Define answer
                        const chunkMessage = JSON.parse(chunk)?.message;
                        if (chunkMessage !== undefined) {
                            // Iterate through each letter to get a typing effect
                            for (let i = 0; i < chunkMessage.length; i++) {
                                answer += chunkMessage[i];
                                setPossibleAnswer(answer + "▌");
                                await new Promise(resolve => setTimeout(resolve, 1));
                            }
                        }
                    }
                }
                setPossibleAnswer(answer);
                const chatId = response.headers.get('chat_id');
                setChat(chatId);
            }
            catch (error) {
                console.error('Error getting answer:', error);
                setAnswering(false);
                toast.error("An error occured when getting a possible answer, please close this popup and try again to submit.", {})
            }
        }
    }

    const handleInfoClose = async (show) => {
        setInfo(show)

        // Clear the suggestion chat
        await axios.post(process.env.REACT_APP_API_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/chatbot/GetAnswer',
        {
            "message": "clear"
        },
        {
            headers: {
                'Authorization': 'Bearer ' + user['accessToken'],
                'accept': 'application/json'
            },
            params: {
                chat_id: chat,
                stream: false,
                skip_user_info: true,
                skip_conversation_title: true
            },
            withCredentials: false,
        });
    }

    return (
        <Layout user={user}>
            {info
            ? <Info
                title={"Possible answer by EVA Support AI"}
                content={possibleAnswer}
                button_text={"Submit anyway"}
                button_action={handleSubmitTicket}
                cancel_action={handleInfoClose}
                chat_id={chat}
            />
            : null}
            <div className="relative flex flex-col h-screen pt-8 md:pt-14 w-full ms-0 items-stretch flex-1 overflow-auto">
                <div className="gap-3 max-w-3xl sm:px-6 lg:px-8 px-6 w-full mx-auto">
                    <div className="mx-auto lg:px-0 pb-10">
                        <h1 className='text-neutral-1 text-2xl md:text-4xl font-bold font-heading'>New Support Ticket</h1>
                    </div>
                    <form className="space-y-4 md:space-y-6 mb-6" onSubmit={handleAnswerSuggestion} >
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Request type</label>
                            <div className="inline-flex gap-3" role="group">
                                {types.map((type) => (
                                    <button
                                        key={type.id}
                                        value={type.name}
                                        onClick={() => setSelectedType(type.name)}
                                        type="button"
                                        className={`w-36 sm:w-52 px-6 py-4 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-black focus:z-10 focus:ring-2 focus:ring-gray-700 focus:text-gray-700 ${selectedType === type.name ? "ring-1 ring-gray-700" : ""}`}
                                    >
                                        <div className='items-center justify-center'>
                                            <type.icon className='w-full h-8 sm:h-10 mb-3' />
                                            <span className='font-normal'>{type.name}</span>
                                        </div>
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Subject</label>
                            <input onBlur={e => setSubject(e.target.value)} value={searchParams.get("subject")} type="text" name="subject" id="subject" className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus-within:border-gray-600 focus-within:ring-1 focus-within:ring-gray-600 block w-full p-2.5 focus:outline-none" placeholder="Why are you creating a new ticket?" required={true}></input>
                        </div>
                        <div>
                            <Listbox value={selectedApplication} onChange={setSelectedApplication}>
                                {({ open }) => (
                                    <>
                                        <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Application</Listbox.Label>
                                        <div className="relative mt-2">
                                            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-600 text-sm sm:leading-6">
                                                <span className="block truncate">{selectedApplication}</span>
                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <ChevronUpDownIcon className="h- w-5 text-gray-400" aria-hidden="true" />
                                                </span>
                                            </Listbox.Button>

                                            <Transition
                                                show={open}
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                                                    {applications.map((application, index) => (
                                                        <Listbox.Option
                                                            key={application}
                                                            className={({ active }) =>
                                                                classNames(
                                                                    active ? 'bg-gray-700 text-white' : 'text-gray-900',
                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                )
                                                            }
                                                            value={application}
                                                        >
                                                            {({ selectedApplication, active }) => (
                                                                <>
                                                                    <span className={classNames(selectedApplication ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                        {application}
                                                                    </span>

                                                                    {selectedApplication ? (
                                                                        <span
                                                                            className={classNames(
                                                                                active ? 'text-white' : 'text-gray-600',
                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                            )}
                                                                        >
                                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                        </span>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    ))}
                                                </Listbox.Options>
                                            </Transition>
                                        </div>
                                    </>
                                )}
                            </Listbox>
                            <div className='flex mt-2 text-xs text-gray-600 items-center'>
                                {loading === true || answering === true || suggestion.length > 0 ?
                                    loading === true && answering === false
                                    ? <div className='flex'>
                                        <SparklesIcon className='w-4 animate-pulse' />                                           
                                        <span className='ml-1.5 w-full'>Getting a suggestion by the magical EVA AI...</span>
                                    </div>
                                    : <div className='flex'>
                                        <SparklesIcon className='w-4' />                                           
                                        <span className='ml-1.5 w-full'>EVA AI suggest that the application of your ticket is <b>{suggestionApplication}</b></span>
                                    </div>
                                : null
                                }
                            </div>
                        </div>
                        <div>
                            <Listbox value={selectedTopic} onChange={setSelectedTopic}>
                                {({ open }) => (
                                    <>
                                        <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Topic</Listbox.Label>
                                        <div className="relative mt-2">
                                            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-600 text-sm sm:leading-6">
                                                <span className="block truncate">{selectedTopic}</span>
                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                </span>
                                            </Listbox.Button>

                                            <Transition
                                                show={open}
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                                                    {topics.map((topic, index) => (
                                                        <Listbox.Option
                                                            key={index}
                                                            className={({ active }) =>
                                                                classNames(
                                                                    active ? 'bg-gray-700 text-white' : 'text-gray-900',
                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                )
                                                            }
                                                            value={topic}
                                                        >
                                                            {({ selectedTopic, active }) => (
                                                                <>
                                                                    <span className={classNames(selectedTopic ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                        {topic}
                                                                    </span>

                                                                    {selectedTopic ? (
                                                                        <span
                                                                            className={classNames(
                                                                                active ? 'text-white' : 'text-gray-600',
                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                            )}
                                                                        >
                                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                        </span>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    ))}
                                                </Listbox.Options>
                                            </Transition>
                                        </div>
                                    </>
                                )}
                            </Listbox>
                            <div className='flex mt-2 text-xs text-gray-600 items-center'>
                                {loading === true || answering === true || suggestion.length > 0 ?
                                    loading === true && answering === false
                                    ? <div className='flex'>
                                        <SparklesIcon className='w-4 animate-pulse' />                                           
                                        <span className='ml-1.5 w-full'>Getting another suggestion by the magical EVA AI...</span>
                                    </div>
                                    : <div className='flex'>
                                        <SparklesIcon className='w-4' />                                           
                                        <span className='ml-1.5 w-full'>My AI feeling is telling me that the topic is <b>{suggestionTopic}</b></span>
                                    </div>
                                : null
                                }
                            </div>
                        </div>
                        <div>
                            <Listbox value={selectedEnvironment} onChange={setSelectedEnvironment}>
                                {({ open }) => (
                                    <>
                                        <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Environment</Listbox.Label>
                                        <div className="relative mt-2">
                                            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-600 text-sm sm:leading-6">
                                                <span className="block truncate">{selectedEnvironment}</span>
                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <ChevronUpDownIcon className="h- w-5 text-gray-400" aria-hidden="true" />
                                                </span>
                                            </Listbox.Button>

                                            <Transition
                                                show={open}
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                                                    {environments.map((environment, index) => (
                                                        <Listbox.Option
                                                            key={environment}
                                                            className={({ active }) =>
                                                                classNames(
                                                                    active ? 'bg-gray-700 text-white' : 'text-gray-900',
                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                )
                                                            }
                                                            value={environment}
                                                        >
                                                            {({ selectedEnvironment, active }) => (
                                                                <>
                                                                    <span className={classNames(selectedEnvironment ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                        {environment}
                                                                    </span>

                                                                    {selectedEnvironment ? (
                                                                        <span
                                                                            className={classNames(
                                                                                active ? 'text-white' : 'text-gray-600',
                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                            )}
                                                                        >
                                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                        </span>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    ))}
                                                </Listbox.Options>
                                            </Transition>
                                        </div>
                                    </>
                                )}
                            </Listbox>
                            <div className='flex mt-2 text-xs text-gray-600 items-center'>
                                {loading === true || answering === true || suggestion.length > 0 ?
                                    loading === true && answering === false
                                    ? <div className='flex'>
                                        <SparklesIcon className='w-4 animate-pulse' />                                           
                                        <span className='ml-1.5 w-full'>Doing some AI stuff to check which environment you meant.</span>
                                    </div>
                                    : <div className='flex'>
                                        <SparklesIcon className='w-4' />                                           
                                        <span className='ml-1.5 w-full'>Hmm difficult one, but our AI nerd thinks the environment of your issue is in <b>{suggestionEnvironment}</b></span>
                                    </div>
                                : null
                                }
                            </div>
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
                            <div className="overflow-hidden border-b border-t rounded-lg border bg-white border-gray-300 focus-within:border-gray-600 focus-within:ring-1 focus-within:ring-gray-600">
                                <textarea
                                    rows={6}
                                    name="description"
                                    id="description"
                                    className="block w-full resize-none border-0 py-0 m-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 outline-none"
                                    placeholder="Add description.."
                                    defaultValue={''}
                                />
                                <div aria-hidden="true">
                                    <div className="py-2">
                                        <div className="h-9" />
                                    </div>
                                    <div className="h-px" />
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="w-30 text-white bg-black hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-400 font-medium rounded-lg text-sm px-8 py-3 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Submit ticket</button>
                        <a href="/tickets" className="w-30 text-gray-600 bg-gray-200 ml-2 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-gray-400 font-medium rounded-lg text-sm px-8 py-3 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Cancel</a>
                    </form>
                </div>
            </div>
        </Layout>
    );
}
