import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import Layout from '../layout/Layout';
import TicketInfo from '../components/TicketInfo';
import TicketDetails from '../components/TicketDetails';
import TicketConversation from '../components/TicketConversation';
import TicketInput from '../components/TicketInput';
import NotFound from './notfound';
import Loading from './loading'

export default function Ticket({ user }) {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hideDetails, setHideDetails] = useState(false);
    const [conversations, setConversations] = useState([]);

    const hideTicketDetails = () => {
        if(hideDetails) {
            console.log(hideDetails)
            setHideDetails(false);
            document.getElementById('ticketDetails').style.display = 'block';
            document.getElementById('hideTicketDetailsTitle').innerHTML = 'Hide ticket details';
            document.getElementById('ticketInfo').style.gridColumn = "span 3 / span 3";
        }
        else {
            setHideDetails(true);
            document.getElementById('ticketDetails').style.display = 'none';
            document.getElementById('hideTicketDetailsTitle').innerHTML = 'Show ticket details';
            document.getElementById('ticketInfo').style.gridColumn = "span 4 / span 4";
        };
    }    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/ticket/GetTicket', {
                    headers: {
                        'Authorization': 'Bearer ' + user['accessToken'],
                        'accept': 'application/json'
                    },
                    params: {
                        id: id
                    },
                    withCredentials: false,
                });
                setData(response.data);
                setConversations(response.data?.conversations);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [user, id]);

    const handleNewReply = (reply) => {
        setConversations([...conversations, reply]);
    }

    return (
        loading ? <Loading /> : !data.id  ? <NotFound /> :
        <Layout user={user}>
            <div className='h-full p-5 sm:px-10 xl:px-48'>
                <div className="py-5 sm:py-5">
                    <div className="mt-4 sm:mt-8 sm:px-2 lg:px-8">
                        <div id='ticketGrid' className='grid lg:grid-rows-2 lg:grid-cols-4 max-sm:gap-4 gap-x-4 lg:gap-x-8'>
                            <div className='lg:col-start-4'>
                                <div id='ticketDetails' className="mx-auto space-y-4 sm:px-4 lg:px-0">
                                    {loading ? (
                                        <p className='text-gray-500'>Loading ticket...</p>
                                    ) : (
                                        <TicketDetails data={data} />
                                    )}
                                </div>
                            </div>
                            <div id='ticketInfo' className='lg:col-span-3 lg:row-start-1'>
                                <div className="mx-auto space-y-4 sm:px-4 lg:px-0">
                                    {loading ? (
                                        <p className='text-gray-500'>Loading ticket...</p>
                                    ) : (
                                        <TicketInfo data={data} onChangeTicketDetails={hideTicketDetails} />
                                    )}
                                    <div className="relative">
                                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                            <div className="w-full border-t border-gray-300" />
                                        </div>
                                        <div className="relative flex justify-center">
                                            <span className="bg-gray-100 px-2 text-sm text-gray-500 font-heading">Messages</span>
                                        </div>
                                    </div>
                                    <div className="mx-auto space-y-4 sm:px-4 lg:px-0">
                                        {loading ? (
                                            <p className='text-gray-500'>Loading ticket...</p>
                                        ) : (
                                            <TicketConversation conversations={conversations} />
                                        )}
                                        <TicketInput id={data.id} user={user} handleNewReply={handleNewReply} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
