import { useState, useEffect } from 'react'
import axios from 'axios';
import { ChevronDownIcon } from '@heroicons/react/20/solid'

import Layout from '../layout/Layout';
import Loading from './loading';
import BarChart from '../components/charts/BarChart';

const filters = [
    { name: 'Today', value: 1 },
    { name: 'Last 7 days', value: 7 },
    { name: 'Last 30 days', value: 30 },
    { name: 'All-time', value: -1 },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Dashboard({ user }) {
    const [filter, setFilter] = useState(1)
    const [data, setData] = useState([])
    const [stats, setStats] = useState([])
    const [timeStats, setTimeStats] = useState({})
    const [activeStats, setActiveState] = useState([])
    const [loading, setLoading] = useState(true);
    const [filterMetric, setFilterMetric] = useState('messages');
    const [filterDatepart, setFilterDatepart] = useState('hour');
    const [filterHierachy, setFilterHierachy] = useState('user');

    useEffect(() => {
        const fetchData = async () => {
            // Date To   
            var date_to = '2023-01-01';
            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + 1);
            date_to = currentDate.toISOString().split('T')[0];
            // Date From    
            var date_from = '2023-01-01';
            if (filter !== -1) {
                const newDate = new Date(currentDate);
                newDate.setDate(currentDate.getDate() - filter);
                date_from = newDate.toISOString().split('T')[0];
            }

            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/chatbot/GetStatistics', {
                    headers: {
                        'Authorization': 'Bearer ' + user['accessToken'],
                        'accept': 'application/json'
                    },
                    params: {
                        date_from: date_from,
                        date_to: date_to,
                        hierachy: filterHierachy
                    },
                    withCredentials: false,
                });
                setData(response.data?.statistics);
                const stat = response.data?.statistics;

                // Set statistics
                setStats([
                    { 'name': 'Users', 'value': stat?.users },
                    { 'name': 'Conversations', 'value': stat?.conversations, 'ratio': 'Started: ' + stat?.conversation_new },
                    { 'name': 'Messages', 'value': stat?.messages_new },
                    { 'name': 'Likes', 'value': stat?.likes, 'ratio': String(Math.round(stat?.like_ratio)) + '%' }
                ])

                // Set active users
                setActiveState([
                    { title: 'Top 10 active domains', name: 'Domain', items: stat?.active_domains },
                    { title: 'Top 10 active users', name: 'User', items: stat?.active_users }
                ])

                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [user, filter, filterHierachy]);

    useEffect(() => {
        const fetchData = async () => {
            // Date To   
            var date_to = '2023-01-01';
            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + 1);
            date_to = currentDate.toISOString().split('T')[0];
            // Date From    
            var date_from = '2023-01-01';
            if (filter !== -1) {
                const newDate = new Date(currentDate);
                newDate.setDate(currentDate.getDate() - filter);
                date_from = newDate.toISOString().split('T')[0];
            }

            // Set datepart filter for api call
            var filterDatetimePart = 'hh';
            if (filterDatepart === 'year') { filterDatetimePart = 'yyyy' };
            if (filterDatepart === 'month') { filterDatetimePart = 'MM' };
            if (filterDatepart === 'day') { filterDatetimePart = 'dd' };
            if (filterDatepart === 'year month') { filterDatetimePart = 'yyyy-MM' };
            if (filterDatepart === 'date') { filterDatetimePart = 'yyyy-MM-dd' };
            if (filterDatepart === 'date hour') { filterDatetimePart = 'yyyy-MM-ddThh' };
            if (filterDatepart === 'weekday') { filterDatetimePart = 'yyyy-MM-dd' };
            if (filterDatepart === 'week') { filterDatetimePart = 'yyyy-MM-dd' };

            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/chatbot/GetTimeStatistics', {
                    headers: {
                        'Authorization': 'Bearer ' + user['accessToken'],
                        'accept': 'application/json'
                    },
                    params: {
                        date_from: date_from,
                        date_to: date_to,
                        datetime_part: filterDatetimePart,
                        metric: filterMetric,
                        hierachy: filterHierachy
                    },
                    withCredentials: false,
                });
                var stat = response.data?.statistics;

                // If weekday get the weekday of each date and operate a group by and sum
                if (filterDatepart === 'weekday') {
                    stat = stat.reduce((acc, entry) => {
                        const timestamp = new Date(entry.timestamp_part);
                        const dayOfWeek = new Intl.DateTimeFormat('en-GB', { weekday: 'long' }).format(timestamp);

                        const existingEntry = acc.find(item => item.timestamp_part === dayOfWeek);
                        if (existingEntry) {
                            existingEntry.amount += entry.amount;
                        } else {
                            acc.push({ "timestamp_part": dayOfWeek, "amount": entry.amount });
                        }

                        return acc;
                    }, []);

                    const daysOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
                    // Sort the result array based on the custom order
                    stat = stat.sort((a, b) => {
                        return daysOrder.indexOf(a.timestamp_part) - daysOrder.indexOf(b.timestamp_part);
                    });
                }

                if (filterDatepart === 'week') {
                    stat = stat.reduce((acc, entry) => {
                        const timestamp = new Date(entry.timestamp_part);
                        // Get the week number of the year for the given timestamp
                        const oneJan = new Date(timestamp.getFullYear(), 0, 1);
                        const weekNumber = Math.ceil(((timestamp - oneJan) / 86400000 + oneJan.getDay() + 1) / 7);
    
                        const existingEntry = acc.find(item => item.timestamp_part === weekNumber);
                        if (existingEntry) {
                            existingEntry.amount += entry.amount;
                        } else {
                            acc.push({ "timestamp_part": weekNumber, "amount": entry.amount });
                        }
                
                        return acc;
                    }, []);
                
                    // Sort the result array based on week numbers
                    stat = stat.sort((a, b) => a.timestamp_part - b.timestamp_part);
                }

                const timestampList = stat.map(item => item?.timestamp_part);
                const amountList = stat.map(item => item?.amount);
                // Set statistics
                setTimeStats({ timestamps: timestampList, amounts: amountList })

                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [user, filter, filterMetric, filterDatepart, filterHierachy]);

    return (
        loading ? <Loading /> :
            <Layout user={user} >
                <div className='h-full p-5 py-2 sm:py-10 sm:px-10 xl:px-48'>
                    <div className="relative isolate">
                        {/* Filter navigation */}
                        <header className="pb-4 pt-6 sm:pb-6">
                            <div className="mx-auto flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
                                <h1 className="hidden sm:flex text-base font-semibold leading-7 text-gray-900">Dashboard</h1>
                                <div className="mr-auto order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-300 sm:pl-6 sm:leading-7">
                                    {filters.map((item) => (
                                        <button key={item.name} value={item.value} onClick={() => setFilter(item.value)} className={item.value === filter ? 'text-sky-700' : 'text-gray-700'}>
                                            {item.name}
                                        </button>
                                    ))}
                                </div>
                                <div className="flex items-center sm:flex-shrink-0 justify-end">
                                    <span className='sm:hidden font-medium text-slate-700 text-sm ml-auto'>
                                        Hierachy:
                                    </span>
                                    <div className="relative">
                                        <select defaultValue={filterHierachy} onChange={e => setFilterHierachy(e.target.value)} className="appearance-none form-select h-9 w-full rounded-lg border-0 bg-transparent bg-none p-0 pl-3.5 pr-[1.875rem] font-medium text-slate-700 focus:shadow-none focus-visible:ring-2 focus-visible:ring-sky-500 text-sm">
                                            <option value="user">User</option>
                                            <option value="company">Company</option>
                                            {user ? user.email.indexOf("@newblack.io") >=0 || user.email.indexOf("@in2intel.com") >=0 ? <option value="all">All</option> : null: null }
                                        </select>
                                        <ChevronDownIcon className='text-slate-500 pointer-events-none absolute inset-y-0 right-2 w-4 h-full' />
                                    </div>
                                </div>
                            </div>
                        </header>

                        {/* Stats */}
                        <div className="mx-auto max-w-7xl sm:px-6">
                            <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0 px-4 bg-white rounded-lg overflow-hidden shadow ring-1 ring-gray-400 ring-opacity-5 sm:rounded-lg">
                                {stats.map((stat, statIdx) => (
                                    <div
                                        key={stat.name}
                                        className={classNames(
                                            statIdx % 2 === 1 ? 'sm:border-l' : statIdx !== 0 ? 'lg:border-l' : '',
                                            'flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-black border-opacity-5 px-4 py-10 sm:px-6 lg:border-1 xl:px-8'
                                        )}
                                    >
                                        <dt className="text-sm font-heading leading-6 text-gray-500">{stat.name}</dt>
                                        <dd className={'text-gray-500 text-xs font-medium'}>{stat.ratio}</dd>
                                        <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                                            {stat.value}
                                        </dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                        <div className='mx-auto max-w-7xl justify-start py-2 px-4 sm:px-8 lg:border-t-0 xl:px-10 text-sm text-gray-500'>
                            An user sends an average of <b>{Math.round(data?.avg_user_messages)}</b> {Math.round(data?.avg_user_messages) === 1 ? 'message' : 'messages'} and has about <b>{Math.round(data?.avg_user_conversations)}</b> {Math.round(data?.avg_user_conversations) === 1 ? 'conversation' : 'conversations'} with <b>{Math.round(data?.avg_conversation_messages)}</b> {Math.round(data?.avg_conversation_messages) === 1 ? 'message' : 'messages'} each.
                        </div>
                    </div>

                    <div className="mt-8">
                        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                            <div className="mx-auto max-w-7xl px-4 sm:px-4">
                                <h2 className="text-base font-semibold leading-6 text-gray-900 lg:mx-0 lg:max-w-none font-heading mb-4">
                                    Stats over time
                                </h2>
                            </div>
                            <div className="overflow-hidden shadow ring-1 ring-gray-400 ring-opacity-5 rounded-lg bg-gray-50">
                                <div className="border-b border-gray-200 bg-gray-50">
                                    <div className="flex flex-wrap sm:flex-nowrap my-2 justify-between ">
                                        <div className="items-center ml-6 hidden sm:flex">
                                            <span className="text-sm font-semibold leading-6 text-gray-900">
                                                Amount of {filterMetric} per {filterDatepart}
                                            </span>
                                        </div>
                                        <div className='flex items-center mx-2'>
                                            <div className="sm:ml-4 flex-shrink-0">
                                                <div className="relative">
                                                    <select defaultValue={filterMetric} onChange={e => setFilterMetric(e.target.value)} className="appearance-none form-select h-9 w-full rounded-lg border-0 bg-transparent bg-none p-0 pl-3.5 pr-[1.875rem] font-medium text-slate-700 focus:shadow-none focus-visible:ring-2 focus-visible:ring-sky-500 text-sm">
                                                        <option value="messages">Messages</option>
                                                        <option value="users">Users</option>
                                                    </select>
                                                    <ChevronDownIcon className='text-slate-500 pointer-events-none absolute inset-y-0 right-2 w-4 h-full' />
                                                </div>
                                            </div>
                                            <div className="mx-1 hidden h-5 w-px bg-slate-900/30 sm:flex"></div>
                                            <div className="flex-shrink-0">
                                                <div className="relative">
                                                    <select defaultValue={filterDatepart} onChange={e => setFilterDatepart(e.target.value)} className="appearance-none form-select h-9 w-full rounded-lg border-0 bg-transparent bg-none p-0 pl-3.5 pr-[1.875rem] font-medium text-slate-700 focus:shadow-none focus-visible:ring-2 focus-visible:ring-sky-500 text-sm">
                                                        <option value="year">Year</option>
                                                        <option value="month">Month</option>
                                                        <option value="week">Week</option>
                                                        <option value="day">Day</option>
                                                        <option value="hour">Hour</option>
                                                        <option value="weekday">Weekday</option>
                                                        <option value="year month">Year month</option>
                                                        <option value="date">Date</option>
                                                        <option value="date hour">Date hour</option>
                                                    </select>
                                                    <ChevronDownIcon className='text-slate-500 pointer-events-none absolute inset-y-0 right-2 w-4 h-full' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white h-64 px-3 overflow-y-hidden py-1 overflow-x-auto'>
                                    <BarChart
                                        chartData={[
                                            {
                                                name: filterMetric[0].toUpperCase() + filterMetric.slice(1),
                                                data: timeStats?.amounts,
                                            }
                                        ]}
                                        chartCategories={timeStats?.timestamps}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {user ? user.email.indexOf("@newblack.io") >=0 || user.email.indexOf("@in2intel.com") >=0 ? 
                        <div>
                            {activeStats.map((group, index) => (
                                <div key={index} className="mt-8">
                                    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                                        <div className="mx-auto max-w-7xl px-4 sm:px-4">
                                            <h2 className="text-base font-semibold leading-6 text-gray-900 lg:mx-0 lg:max-w-none font-heading mb-2">
                                                {group.title}
                                            </h2>
                                        </div>
                                        <div className="inline-block min-w-full py-2 align-middle">
                                            <div className="overflow-hidden shadow ring-1 ring-gray-400 ring-opacity-5 rounded-lg">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <thead className="bg-gray-50">
                                                        <tr>
                                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                Name
                                                            </th>
                                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                Messages
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-gray-200 bg-white">
                                                        {group.items.map((item, index) => (
                                                            <tr key={index}>
                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                    {item.name}
                                                                </td>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.messages}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    : null: null }
                    <div className='h-10'></div>
                </div>
            </Layout>
    );
}
